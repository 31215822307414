import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, resolveComponent as _resolveComponent, withCtx as _withCtx, createCommentVNode as _createCommentVNode, Transition as _Transition, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "conBox"
};
const _hoisted_2 = {
  class: "Body"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_view = _resolveComponent("router-view");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [$setup.isKeep ? (_openBlock(), _createBlock(_component_router_view, {
    key: 0
  }, {
    default: _withCtx(({
      Component
    }) => [(_openBlock(), _createBlock(_KeepAlive, null, [(_openBlock(), _createBlock(_resolveDynamicComponent(Component)))], 1024))]),
    _: 1
  })) : _createCommentVNode("", true), !$setup.isKeep ? (_openBlock(), _createBlock(_component_router_view, {
    key: 1
  }, {
    default: _withCtx(({
      Component
    }) => [_createVNode(_Transition, null, {
      default: _withCtx(() => [(_openBlock(), _createBlock(_resolveDynamicComponent(Component)))]),
      _: 2
    }, 1024)]),
    _: 1
  })) : _createCommentVNode("", true)])]);
}