import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = {
  class: "body"
};
const _hoisted_2 = {
  class: "content"
};
const _hoisted_3 = {
  class: "m-hide"
};
const _hoisted_4 = {
  class: "logo"
};
const _hoisted_5 = ["src"];
const _hoisted_6 = {
  class: "m-show"
};
const _hoisted_7 = {
  class: "menuBtn"
};
const _hoisted_8 = {
  class: "m-show"
};
const _hoisted_9 = {
  class: "m-title"
};
const _hoisted_10 = {
  class: "operateBox"
};
const _hoisted_11 = {
  class: "sysname m-hide"
};
const _hoisted_12 = ["src"];
const _hoisted_13 = {
  class: "m-hide"
};
import { ref, reactive, computed, getCurrentInstance, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
export default {
  __name: 'header',
  emits: ["showmenu"],

  setup(__props, {
    emit: __emit
  }) {
    const _this = getCurrentInstance()?.appContext.config.globalProperties;

    const router = useRouter();
    const store = useStore(); // 父组件方法

    const emit = __emit; // Vuex变量

    const state = computed(() => {
      // 使用计算属性来监听数据的变化
      return store.state;
    });
    const data = reactive({
      logo: require("@/assets/logo.png"),
      avatar: state.value.adminUserInfo.avator ? _this.$imgapi + state.value.adminUserInfo.avator : require("@/assets/touxiang.png"),
      showDrawer: false,
      hasLogin: true,
      msgNumber: 6,
      nickname: state.value.adminUserInfo.nickname,
      // 是否互动
      okk: false,
      ado: require("@/assets/dd.wav")
    });
    onMounted(() => {
      init();
      window.addEventListener("mousedown", handleonmousedown);
      ontimes();
    });

    const handleonmousedown = () => {
      if (data.okk) {
        return;
      }

      data.okk = true;
    }; // 计时器


    const ontimes = () => {
      console.log("计时器开始，每个XX秒获取一次最新订单信息");
      data.timer = setInterval(() => {
        init();
      }, 20000);
    }; // 查询用户


    const init = () => {
      loadData();
    }; // 请求数据


    const loadData = async () => {
      let today = _this.$time("d") + " 00:00:00";
      let end = _this.$getDateStr(today, 1) + " 00:00:00"; // 请求数据

      let data = {
        // 查询条件
        user_id: '',
        // 查询条件
        order_id: "",
        // 搜索订单号
        buyer_name: "",
        // 搜索下单账号
        receive: "",
        // 搜索收货人姓名
        receive_phone: "",
        // 搜索收货电话
        pay_number: "",
        // 搜索交易单号
        order_type: "",
        // 专区类型
        status: 3,
        // 状态
        // 时间范围
        start_time: '2024-02-01 00:00:00',
        end_time: end,
        // 分页查询
        page: 1,
        // 当前页
        page_size: 9999,
        // 每页数量
        // 排序
        sort_str: "create_at",
        // 要排序的字段
        sort_type: "DESC" // 正序或倒叙，ASC / DESC

      };
      let url = _this.$appapi + "order/show";
      let res = await _this.$toPost(url, data);

      if (res.code == 200) {
        culr(res.data.data);
      } else {
        culr([]);
      }
    }; // 计算新订单条数


    const culr = async arr => {
      let newnum = arr.length; // for (const item of arr) {
      //   if (item.status == 1) {
      //     newnum++
      //   }
      // }

      if (newnum > state.value.msg) {
        console.log("数量增加了", newnum);
        store.commit("setMsg", newnum); // data.msgNumber = newnum

        if (data.okk) {
          toPhonation();
        }
      } else {
        console.log("数量没有变化", newnum);
        store.commit("setMsg", newnum); // data.msgNumber = newnum
      }
    }; // 发出声音


    const toPhonation = () => {
      console.log("播放声音");
      let audio = new Audio();
      audio.src = data.ado;

      try {
        audio.play();
      } catch (error) {
        console.log("播放遇到问题了啊啊啊");
      }
    }; // const direction = ref("rtl");


    const inform = () => {
      console.log("目前有" + data.msgNumber + "条消息");
      let orderPathArr = ["order", "订单管理", "订单列表"];
      changeBreadC2(orderPathArr);
      changeMenu2("order");
      router.push({
        name: "order"
      });
    }; // 修改菜单选中


    const changeMenu2 = newVal => {
      store.commit("changeMenu", newVal); // 触发 vuex 里定义的函数
    }; // 修改面包屑参数


    const changeBreadC2 = newVal => {
      store.commit("changeBreadCrumb", newVal); // 触发 vuex 里定义的函数
    }; //


    const showpopover = () => {
      console.log("弹出退出框");
    }; // 修改面包屑参数


    const changeBreadC = () => {
      let pathArr = ["setting", "设置中心"];
      store.commit("changeBreadCrumb", pathArr); // 触发 vuex 里定义的函数
    }; // 修改菜单选中


    const changeMenu = () => {
      store.commit("changeMenu", "setting"); // 触发 vuex 里定义的函数
    }; // 去设置


    const toSetting = () => {
      console.log("去设置"); // 改变vuex变量

      changeBreadC();
      changeMenu();
      router.push({
        name: "setting"
      });
    }; // 退出系统


    const exitLogin = () => {
      console.log("退出登录"); // const JSON_token = localStorage.getItem("adminUserToken");
      // let tokenInfo = JSON.parse(JSON_token);
      // tokenInfo.token = null;
      // let JSON_data = JSON.stringify(tokenInfo);

      sessionStorage.removeItem("adminUserToken");
      sessionStorage.removeItem("lhShopAdminUserToken");
      router.push({
        name: "login"
      });
    }; // 显示菜单


    const showMenu = () => {
      emit("showmenu");
    };

    return (_ctx, _cache) => {
      const _component_Operation = _resolveComponent("Operation");

      const _component_el_icon = _resolveComponent("el-icon");

      const _component_Bell = _resolveComponent("Bell");

      const _component_el_badge = _resolveComponent("el-badge");

      const _component_el_tooltip = _resolveComponent("el-tooltip");

      const _component_switch_button = _resolveComponent("switch-button");

      const _component_el_popover = _resolveComponent("el-popover");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_cache[1] || (_cache[1] = _createElementVNode("div", {
        class: "loadProgress"
      }, [_createElementVNode("div", {
        class: "fill"
      })], -1)), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("img", {
        src: data.logo,
        alt: "logo"
      }, null, 8, _hoisted_5), _createElementVNode("span", null, _toDisplayString(_ctx.$webInfo.spimpleName), 1)])]), _createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [_createVNode(_component_el_icon, {
        onClick: showMenu
      }, {
        default: _withCtx(() => [_createVNode(_component_Operation)]),
        _: 1
      })])]), _createElementVNode("div", _hoisted_8, [_createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$webInfo.name), 1)]), _createElementVNode("div", _hoisted_10, [_createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$webInfo.name), 1), _createElementVNode("div", {
        class: "inform",
        onClick: inform
      }, [_createVNode(_component_el_tooltip, {
        content: '有' + state.value.msg + '个待发货订单',
        placement: "bottom"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_badge, {
          value: state.value.msg,
          max: 99,
          class: "item"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_icon, {
            size: 18
          }, {
            default: _withCtx(() => [_createVNode(_component_Bell)]),
            _: 1
          })]),
          _: 1
        }, 8, ["value"])]),
        _: 1
      }, 8, ["content"])]), _createVNode(_component_el_popover, {
        placement: "bottom",
        width: 60,
        trigger: "click",
        "popper-class": "popperStyle"
      }, {
        reference: _withCtx(() => [data.hasLogin ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "portrait",
          onClick: showpopover
        }, [_createElementVNode("img", {
          src: data.avatar
        }, null, 8, _hoisted_12), _createElementVNode("span", _hoisted_13, _toDisplayString(data.nickname), 1)])) : _createCommentVNode("", true)]),
        default: _withCtx(() => [_createElementVNode("div", {
          class: "exitLogin",
          onClick: exitLogin
        }, [_createVNode(_component_el_icon, {
          size: 17
        }, {
          default: _withCtx(() => [_createVNode(_component_switch_button)]),
          _: 1
        }), _cache[0] || (_cache[0] = _createTextVNode("退出系统 "))])]),
        _: 1
      })])])]);
    };
  }

};