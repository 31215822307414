import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "body"
};
const _hoisted_2 = {
  class: "content"
};
const _hoisted_3 = {
  class: "container hasScroll"
};
import { ref, reactive, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import Header from "@/components/header.vue";
import Menu from "@/components/menu.vue";
import Container from "@/components/container.vue";
import Breadcrumb from "@/components/breadcrumb.vue";
export default {
  __name: 'index',

  setup(__props) {
    const router = useRouter();
    const store = useStore();
    onMounted(() => {
      // 修改当前选中菜单
      // const currentPathName = router.currentRoute._rawValue.name;
      // 修改面包屑导航
      const thisRoutes = router.options.routes[1].children;
      console.log(thisRoutes);
      let newRouterArr = [];
      let tempArr = [];

      for (const item of thisRoutes) {
        if (item.meta.group !== "") {
          let currentGroup = item.meta.group;
          let key = tempArr.indexOf(currentGroup);

          if (key !== -1) {
            let newObj = {
              icon: item.meta.icon,
              path: item.name,
              info: item.meta.info,
              name: item.meta.name,
              role: item.meta.role
            };
            newRouterArr[key].children.push(newObj);
          } else {
            tempArr.push(currentGroup);
            let newArr = {
              group: currentGroup,
              name: item.meta.groupName,
              role: item.meta.role,
              children: []
            };
            let newObj = {
              icon: item.meta.icon,
              path: item.name,
              info: item.meta.info,
              name: item.meta.name,
              role: item.meta.role
            };
            newArr.children.push(newObj);
            newRouterArr.push(newArr);
          }
        } else {
          tempArr.push("233");
          let newObj = { ...item.meta,
            path: item.name
          };
          newRouterArr.push(newObj);
        }
      }

      store.commit("setPage", newRouterArr);
    });
    const myMenuRef = ref(null);

    const showmenu = () => {
      myMenuRef.value.mFold();
    };

    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(Header, {
        onShowmenu: showmenu
      }), _createElementVNode("div", _hoisted_2, [_createVNode(Menu, {
        ref_key: "myMenuRef",
        ref: myMenuRef
      }, null, 512), _createElementVNode("div", _hoisted_3, [_createVNode(Breadcrumb), _createVNode(Container)])])]);
    };
  }

};