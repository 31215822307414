import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "lose"
};
const _hoisted_2 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("img", {
    src: _ctx.loseImg
  }, null, 8, _hoisted_2), _createElementVNode("button", {
    onClick: _cache[0] || (_cache[0] = (...args) => $setup.toindex && $setup.toindex(...args))
  }, "返回首页")]);
}