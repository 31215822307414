// 站点信息
export const webinfo = {
    name: '小幕僚康养精选管理平台',
    spimpleName: '康养精选',
    company: '小幕僚咨询'
}

// 正式服
export const imgapi = "http://oss.waken028.com/"; // OSS地址
export const api = "https://server.waken028.com/api/admin/";
export const appapi = "https://server.waken028.com/api/";
export const uploadApi = "https://server.waken028.com/api/upload";
export const delOssApi = "https://server.waken028.com/api/del_img/";


// 测试服API地址
// export const imgapi = "http://oss.waken028.com/"; // OSS地址
// export const api = "http://localhost:8001/api/admin/";
// export const appapi = "http://localhost:8001/api/";
// export const uploadApi = "http://localhost:8001/api/upload";
// export const delOssApi = "http://localhost:8001/api/del_img/";


// 商户号
export const shh = 1652311850;
// 腾讯地图Key
export const txMapKey = "BPQBZ-JOHWT-AQWXK-VTCIX-U6P67-PVFR5";