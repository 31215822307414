import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '@/assets/logo.png';
const _hoisted_1 = {
  class: "pagebox"
};
const _hoisted_2 = {
  class: "LoginBox bg-purple"
};
const _hoisted_3 = {
  class: "clearfix"
};
const _hoisted_4 = {
  class: "imgBox"
};
const _hoisted_5 = {
  class: "text item"
};
const _hoisted_6 = {
  key: 4,
  class: "cuttype"
};
const _hoisted_7 = {
  class: "btnBox"
};
const _hoisted_8 = {
  class: "bottomInfo"
};
import { onMounted, getCurrentInstance, ref, reactive } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { ElNotification, ElMessage } from "element-plus";
import Base64 from 'base-64';
export default {
  __name: 'login',

  setup(__props) {
    const router = useRouter();
    const store = useStore();
    console.log(store);

    const _this = getCurrentInstance()?.appContext.config.globalProperties;

    const ruleFormEl = ref();
    const lcData = reactive({
      loginType: [// {
      //   label: "财务登录",
      //   value: "caiwu",
      //   text: "财务登录入口"
      // },
      // {
      //   label: "库管登录",
      //   value: "kuguan",
      //   text: "库管登录入口"
      // },
      {
        label: "管理员登录",
        value: "admin",
        text: "康养精选后台登录"
      }],
      current: "admin",
      title: "康养精选后台登录",
      //
      issend: false,
      login: "account",
      loginText: "使用验证码登录",
      veriyCode: "发送验证码",
      rescode: ""
    });
    const ruleForm = reactive({
      password: "",
      username: "",
      mobile: "",
      code: ""
    }); // 验证

    const rules = reactive({
      password: [{
        required: true,
        message: "请输入登录密码",
        trigger: "blur"
      }],
      username: [{
        required: true,
        message: "请输入登录账号",
        trigger: "blur"
      }],
      mobile: [{
        required: true,
        message: "请输入手机号",
        trigger: "blur"
      }],
      code: [{
        required: true,
        message: "请输入短信验证码",
        trigger: "blur"
      }]
    }); // 加载时触发

    onMounted(() => {
      // 查找缓存
      let lastLoginType = sessionStorage.getItem("lastLoginType");

      if (lastLoginType) {
        // 切换登录方式
        cut(lastLoginType, true);
      }

      let JSON_token = sessionStorage.getItem("lhShopAdminUserToken");
      console.log("判断有没有登录的token", JSON_token);

      if (JSON_token) {
        // 解码缓存
        let tokenInfo = JSON.parse(JSON_token);
        console.log(tokenInfo);
        console.log(tokenInfo.time); // 如果是平台，可以直接登录

        if (tokenInfo.role == 'admin') {
          let curTime = _this.$time();

          if (!tokenInfo.token) {
            ElMessage.warning("登录信息已失效，重新登录");
            return;
          }

          if (_this.$dateDiff(curTime, tokenInfo.time) > 7) {
            ElMessage.warning("超过7天未登录，请重新登录");
            return;
          } // 验证token信息，暂时没写


          console.log("有账号登录信息，直接登录");
          ruleForm.username = tokenInfo.username;
          ruleForm.password = tokenInfo.password;
          ElMessage.success("自动登录...");
          setTimeout(() => {
            submitForm();
          }, 1000);
        }
      } else {
        console.log("无缓存，手动登录");
      }
    }); // 方法

    const handleClick = async e => {
      cut(e.props.name);
    };

    const cut = (name, qt = false) => {
      switch (name) {
        case "admin":
          {
            lcData.title = "管理员登录入口";

            if (qt) {
              lcData.current = "admin";
            }

            lcData.login = "mobile";
            cutcode();
          }
          break;

        case "caiwu":
          {
            lcData.title = "财务登录入口";

            if (qt) {
              lcData.current = "caiwu";
            }
          }
          break;

        case "kuguan":
          {
            lcData.title = "库管登录入口";

            if (qt) {
              lcData.current = "kuguan";
            }
          }
          break;
      }
    }; // 切换登录方式


    const cutcode = async e => {
      if (lcData.login == "account") {
        lcData.loginText = "使用账号密码登录";
        lcData.login = "mobile";
      } else {
        lcData.loginText = "使用验证码登录";
        lcData.login = "account";
      }
    }; // 发送验证码


    const tocode = async e => {
      console.log('验证码已发送'); // 验证手机号

      if (!_this.$phoneFun(ruleForm.mobile)) {
        ElMessage.error("请填写正确的手机号");
        return;
      }

      if (!lcData.issend) {
        // 验证账号是否存在
        // let hasmobil = await checkMobile()
        // if (!hasmobil) {
        //   ElMessage.error("该手机号尚未开头服务");
        //   return
        // }
        sendCode();
        lcData.veriyCode = '60s 后重发';
        lcData.issend = true;
        let timenum = 59;
        let siv = setInterval(() => {
          if (timenum == 0) {
            clearInterval(siv);
            lcData.issend = false;
            lcData.veriyCode = '重发验证码';
          } else {
            lcData.veriyCode = timenum + 's 后重发';
            timenum--;
          }
        }, 1000);
      }
    };

    const sendCode = async e => {
      let url = _this.$appapi + "get_sms_code/";
      let res = await _this.$toGet(url, ruleForm.mobile);

      if (res.code == 200) {
        ElMessage({
          message: '验证码已发送',
          type: 'success'
        });
        lcData.rescode = Base64.decode(res.data); // console.log('返回的验证吗是', lcData.rescode)
      } else {
        ElMessage.error(res.msg);
      }
    }; // 验证账号是否存在


    const checkMobile = async e => {
      let url = _this.$appapi + "common/verify_phone";
      let data = {
        phone: ruleForm.mobile,
        role: lcData.current
      };
      let res = await _this.$toPost(url, data);

      if (res.code == 200) {
        return true;
      }

      return false;
    }; // 登录验证
    // 点击登录


    const submitForm = async () => {
      // 表单验证
      await ruleFormEl.value.validate((valid, fields) => {
        if (valid) {
          console.log("submit!");

          if (lcData.login == 'mobile') {
            if (!_this.$phoneFun(ruleForm.mobile)) {
              ElMessage.error("请填写正确的手机号");
              return;
            }
          }

          toLogin();
        } else {
          console.log("error submit!", fields);
          ElMessage.error("请填写正确的登录信息");
        }
      });
    }; // 登录


    const toLogin = async () => {
      // 请求数据
      let data = {}; // 请求地址

      let url = ""; // 组合值

      if (lcData.current == "admin") {
        url = _this.$api + "index/login";
        data = {
          name: ruleForm.username,
          pwd: ruleForm.password
        };
      } else if (lcData.current == "caiwu" || lcData.current == "kuguan") {
        url = _this.$appapi + "role/user/role_login";

        if (lcData.login == "mobile") {
          if (ruleForm.code != lcData.rescode) {
            ElMessage.error("验证码不正确");
            return;
          }
        }

        data = {
          user_name: lcData.login == "account" ? ruleForm.username : ruleForm.mobile,
          password: lcData.login == "account" ? ruleForm.password : 123456,
          register: lcData.login == "account" ? "account" : "mobile"
        };
      }

      console.log(url, data);
      let res = await _this.$toPost(url, data);

      if (res.code == 200) {
        // 判断身份
        if (lcData.current == "caiwu" && res.data.role_id != 1) {
          ElNotification({
            title: "异常",
            message: "仅支持财务账号登录",
            type: "warning"
          });
          return;
        } else if (lcData.current == "kuguan" && res.data.role_id != 2) {
          ElNotification({
            title: "异常",
            message: "仅支持库管账号登录",
            type: "warning"
          });
          return;
        } // 登录，并将用户信息存入VUEX


        let newdata = { ...res.data,
          role: lcData.current,
          nickname: lcData.current == 'admin' ? '管理员' : res.data.name
        };
        store.commit("login", newdata); //  缓存登录信息

        let randomNumber = Math.floor(Math.random() * 9999) + 1;
        let loginToken = {
          time: _this.$time(),
          role: lcData.current,
          username: ruleForm.username,
          password: ruleForm.password,
          token: "login-token-" + randomNumber
        };
        let JSON_data = JSON.stringify(loginToken);
        sessionStorage.setItem("lhShopAdminUserToken", JSON_data);
        console.log(JSON_data); // 缓存上一次登录的方式

        let lastType = lcData.current;
        sessionStorage.setItem("lastLoginType", lastType);
        ElNotification({
          title: "成功提示",
          message: "登录成功",
          type: "success"
        });

        if (lcData.current == "admin") {
          router.push({
            name: "index"
          });
        } else if (lcData.current == "caiwu") {
          router.push({
            name: "purchaseorder"
          });
        } else if (lcData.current == "kuguan") {
          router.push({
            name: "purchaseorder"
          });
        }
      } else {
        ElNotification({
          title: "登录失败",
          message: res.msg,
          type: "error",
          duration: 2000
        });
      }
    };

    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");

      const _component_el_form_item = _resolveComponent("el-form-item");

      const _component_el_link = _resolveComponent("el-link");

      const _component_el_button = _resolveComponent("el-button");

      const _component_el_form = _resolveComponent("el-form");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_cache[4] || (_cache[4] = _createElementVNode("img", {
        src: _imports_0
      }, null, -1)), _createElementVNode("span", null, _toDisplayString(lcData.title), 1)])]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_el_form, {
        "status-icon": "",
        ref_key: "ruleFormEl",
        ref: ruleFormEl,
        rules: rules,
        model: ruleForm,
        "label-position": "top"
      }, {
        default: _withCtx(() => [lcData.login == 'account' ? (_openBlock(), _createBlock(_component_el_form_item, {
          key: 0,
          label: "登录账号",
          prop: "username"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            size: "large",
            modelValue: ruleForm.username,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ruleForm.username = $event),
            modelModifiers: {
              number: true
            },
            placeholder: "请输入后台登录账号"
          }, null, 8, ["modelValue"])]),
          _: 1
        })) : _createCommentVNode("", true), lcData.login == 'account' ? (_openBlock(), _createBlock(_component_el_form_item, {
          key: 1,
          label: "登录密码",
          "show-password": "",
          prop: "password"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            size: "large",
            type: "password",
            modelValue: ruleForm.password,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ruleForm.password = $event),
            placeholder: "请输入登录密码",
            autocomplete: "off",
            onKeyup: _withKeys(submitForm, ["enter"])
          }, null, 8, ["modelValue"])]),
          _: 1
        })) : _createCommentVNode("", true), lcData.login == 'mobile' ? (_openBlock(), _createBlock(_component_el_form_item, {
          key: 2,
          label: "手机号",
          prop: "mobile"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            size: "large",
            modelValue: ruleForm.mobile,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ruleForm.mobile = $event),
            modelModifiers: {
              number: true
            },
            placeholder: "请输入手机号"
          }, null, 8, ["modelValue"])]),
          _: 1
        })) : _createCommentVNode("", true), lcData.login == 'mobile' ? (_openBlock(), _createBlock(_component_el_form_item, {
          key: 3,
          label: "验证码",
          "show-password": "",
          prop: "code"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: ruleForm.code,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ruleForm.code = $event),
            placeholder: "请输入6位短信验证码"
          }, {
            append: _withCtx(() => [_createElementVNode("span", {
              class: "verifycode",
              onClick: tocode
            }, _toDisplayString(lcData.veriyCode), 1)]),
            _: 1
          }, 8, ["modelValue"])]),
          _: 1
        })) : _createCommentVNode("", true), lcData.current != 'admin' ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createVNode(_component_el_link, {
          type: "primary",
          underline: false,
          onClick: cutcode
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(lcData.loginText), 1)]),
          _: 1
        })])) : _createCommentVNode("", true), _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_7, [_createVNode(_component_el_button, {
            size: "large",
            class: "loginBtn",
            type: "primary",
            onClick: submitForm
          }, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [_createTextVNode("登 录")])),
            _: 1
          })])]),
          _: 1
        })]),
        _: 1
      }, 8, ["rules", "model"])])]), _createElementVNode("div", _hoisted_8, [_createElementVNode("span", null, "© 2023 " + _toDisplayString(_ctx.$webInfo.company) + " 版权所有", 1), _cache[6] || (_cache[6] = _createElementVNode("span", null, [_createTextVNode("备案号："), _createElementVNode("a", {
        target: "blank",
        href: "https://beian.miit.gov.cn/"
      }, "渝ICP备2023001390号-1")], -1))])]);
    };
  }

};