import { createApp } from "vue";
// 导入vue实例
import App from "./App.vue";
import store from "./store";
import router from "./router";
// 导入element plus
import ElementPlus from "element-plus";
import zhCn from "element-plus/es/locale/lang/zh-cn";
import "element-plus/dist/index.css";
import * as ElIcons from "@element-plus/icons-vue";
// 导入axios
import axios from "axios";
import VueAxios from "vue-axios";
import "@/axios";
// 导入公共样式
import "@/assets/public.scss";
import "@/assets/media.scss";
// 导入配置变量
import { imgapi, api, appapi, uploadApi, webinfo } from "@/utils/config.js";
// 导入drag方法
import { drag } from '@/utils/drag.js'
// 导入公共方法
import {
  toGet,
  toPost,
  delOssImg,
  dateToString,
  stringToDate,
  clearObj,
  time,
  getDateStr,
  dateDiff,
  toCopy,
  downSort,
  upSort,
  phoneFun,
  isJson,
  beforeAvatarUpload
} from "@/utils/public.js";

const app = createApp(App);
// 注册drag指令
app.directive('drag', drag);
// 请求方法
app.config.globalProperties.$toGet = toGet;
app.config.globalProperties.$toPost = toPost;
// 时间方法
app.config.globalProperties.$dateToString = dateToString;
app.config.globalProperties.$stringToDate = stringToDate;
// 公共小工具
app.config.globalProperties.$toCopy = toCopy;
app.config.globalProperties.$clearObj = clearObj;
app.config.globalProperties.$time = time;
app.config.globalProperties.$getDateStr = getDateStr;
app.config.globalProperties.$dateDiff = dateDiff;
app.config.globalProperties.$downSort = downSort;
app.config.globalProperties.$upSort = upSort;
app.config.globalProperties.$phoneFun = phoneFun;
app.config.globalProperties.$isJson = isJson;
// 图片上传前判断合不合格
app.config.globalProperties.$beforeAvatarUpload = beforeAvatarUpload;

// 判断设备尺寸
app.config.globalProperties.$isM = document.body.clientWidth < 768;

// 删除OSS图片
app.config.globalProperties.$delOssImg = delOssImg;

// oss图片域名
app.config.globalProperties.$imgapi = imgapi;
// 接口地址
app.config.globalProperties.$api = api;
app.config.globalProperties.$appapi = appapi;
app.config.globalProperties.$uploadApi = uploadApi;
// 站点信息
app.config.globalProperties.$webInfo = webinfo;

// 图标
for (let iconName in ElIcons) {
  app.component(iconName, ElIcons[iconName]);
}
app
  .use(store)
  .use(router)
  .use(VueAxios, axios)
  .use(VueAxios, axios)
  .use(ElementPlus, { locale: zhCn })
  .mount("#app");
